import { Link } from 'react-router-dom';
import { globalConstants } from '../../utils/constants/global';
import { Line } from '../Layout.styles';
import { Markdown } from '../markdown';
import {
  FooterSectionContainer,
  FooterLinkSection,
  FooterLinks,
  PoweredBySection,
} from './Footer.styles';
import { LandscapeLogo } from '../../images/LandscapeLogo';

export const Footer = () => {
  const { footerLinks } = globalConstants;

  const httpRegex =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/; //eslint-disable-line

  return (
    <>
      <Line />
      <FooterSectionContainer>
        <FooterLinkSection>
          <FooterLinks>
            <div>
              {footerLinks.map((item, index) => {
                return (
                  <div key={item.link}>
                    {httpRegex.test(item.url) ? (
                      <a href={item.url} target={item.target}>
                        <Markdown
                          children={item.link}
                          className="footerLinkText"
                        />
                      </a>
                    ) : (
                      <Link to={item.url}>
                        <Markdown
                          children={item.link}
                          className="footerLinkText"
                        />
                      </Link>
                    )}

                    {footerLinks.length > index + 1 && (
                      <Markdown children={'|'} className="divider" />
                    )}
                  </div>
                );
              })}
            </div>
          </FooterLinks>
        </FooterLinkSection>
        <Markdown
          children={process.env.REACT_APP_DISCLAIMER_TEXT ?? ''}
          className="subText"
        />
        <PoweredBySection>
          <div>Powered By</div>
          <LandscapeLogo height={50} />
        </PoweredBySection>
      </FooterSectionContainer>
    </>
  );
};
