import styled from 'styled-components/macro';
import { device } from '../../styles/devices';
import { ISectionContainerProps } from '../Layout.styles';

export const FooterSectionContainer = styled.div<ISectionContainerProps>`
  padding: 10px 10px;
  @media ${device.mobileMed} {
    padding: 20px 20px;
  }
  display: ${({ align }) => (align ? 'flex' : 'inherit')};
  flex-direction: ${({ align }) => (align ? 'column' : 'inherit')};
  justify-content: ${({ align }) =>
    align === 'left'
      ? 'flex-start'
      : align === 'center'
      ? 'center'
      : align === 'right'
      ? 'flex-end'
      : 'inherit'};
`;
export const FooterLinkSection = styled.div`
  margin: 30px 20px;
  display: flex;
  justify-content: center;
`;

export const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 20px;
  text-align: center;
  * {
    display: inline;
    color: ${({ theme }) => theme.colors.text.black};
  }

  .divider {
    padding: 0 5px;
  }
`;

export const PoweredBySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;
