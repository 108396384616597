import { FC, SVGProps } from 'react';

export const LandscapeLogo: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    viewBox="0 0 350 106.9"
    {...props}
  >
    <style>
      {
        '.st0,.st1,.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#09a8fa}.st1,.st2{fill:#282a37}.st2{fill:#373435}'
      }
    </style>
    <g id="Group_43">
      <path
        id="Path_1"
        d="m29.7 35.7 2 2 9 9 13 13 13-13 6.4-6.4 4.3-4.3 2.1-2.1 3.9-3.9 5.6 5.6 17.8 17.9-26.6 26.7-26.7 26.7-26.8-26.7L0 53.4l18.2-18.2 5.6-5.6 4 4 1.9 2.1zM83 41.5 72.1 52.3 53.6 70.8 35 52.3l-11-11-12 12.1 20.8 20.8L53.5 95l20.8-20.8 20.6-20.8L83 41.5z"
        className="st0"
      />
      <path
        id="Path_2"
        d="m33.9 27.7-4-4.1L53.4 0l23.9 23.9-3.9 3.8-2.1 2.1L53.4 12 35.8 29.6l-1.9-1.9z"
        className="st0"
      />
    </g>
    <path
      d="M126.8 22.5h8.7l13.4 34.4 13.4-34.4h8.5l-18.6 45h-6.8zM191.6 32c-8.8 20.6-6.6 15.5-15.1 35.6h-8l19.6-45h7.2l19.6 44.9h-8.3c-7.5-17.9-7-17-15-35.5zM240 67.5c-2.6.1-5.3-.4-7.7-1.2-2.2-.8-4.3-2-5.9-3.7-1.7-1.7-3-3.8-3.8-6.1-.9-2.7-1.4-5.5-1.3-8.4V22.6h7.7v25.1c0 4.2 1 7.3 3 9.4 2.2 2.2 5.2 3.4 8.3 3.2 3.1.2 6-1 8.2-3.1 2-2.1 3-5.1 3-9.2V22.5h7.7v25.2c0 2.9-.4 5.9-1.3 8.7-.8 2.3-2.2 4.4-3.9 6.2-1.7 1.7-3.7 3-5.9 3.8-2.6.8-5.4 1.2-8.1 1.1zM271.9 22.5h7.8v38.1H303v6.9h-31v-45zM318.7 29.7h-14v-7.2h35.8v7.2h-14v37.8h-7.9V29.7z"
      className="st1"
    />
    <path
      id="Path_4"
      d="M127.7 81.1v3.1h-.9v-8.6h3.1c1.7 0 3.2.8 3.2 2.7 0 1.9-1.6 2.8-3.3 2.8h-2.1zm17.5.8h-4.7l-1.1 2.3h-.9l4-8.7h.7l4 8.7h-1l-1-2.3zm11.1-1.1v3.4h-.9v-3.3l-3.7-5.2h1.1l3.1 4.3 3.1-4.3h1.1l-3.8 5.1zm17.6-3.5-3.1 4.5h-.2l-3.1-4.5v7h-.8v-8.6h.8l3.2 4.8 3.2-4.8h.8v8.6h-.7l-.1-7zm9.3-.8v3h4.8v.8h-4.8v3.1h5.3v.8h-6.2v-8.6h6.1v.8l-5.2.1zm13.4.5v7.1h-.8v-8.6h.8l5.5 7v-7h.8v8.6h-.6l-5.7-7.1zm17.1-.5v7.8h-.9v-7.8h-2.9v-.8h6.7v.8h-2.9zm19.3 5.4c-.1 1.4-1.3 2.5-2.7 2.5h-.2c-1.3 0-2.5-.5-3.4-1.4l-.1-.1.5-.6.1.1c.8.8 1.8 1.2 2.9 1.2.9 0 2-.5 2-1.5 0-1.2-1.3-1.4-2.2-1.6-1.4-.3-2.9-.8-2.9-2.4.1-1.4 1.3-2.5 2.7-2.4h.1c1.1-.1 2.1.3 2.9 1.1l.1.1-.5.7-.1-.1c-.7-.6-1.5-.9-2.4-.9-.9 0-1.9.5-1.9 1.4 0 1.3 1.3 1.4 2.3 1.7 1.3 0 2.8.6 2.8 2.2zm15.4-2c0 2.4-1.9 4.4-4.2 4.4-2.4 0-4.3-1.9-4.4-4.3v-.1c-.1-2.4 1.8-4.4 4.2-4.4 2.4-.1 4.4 1.8 4.4 4.2v.2zm8.2 3.4h4.9v.8h-5.8v-8.6h.9v7.8zm18.6-2.8c0 2.3-1.3 3.8-3.5 3.8-1.8.1-3.4-1.3-3.5-3.2v-5.5h.9v5c-.2 1.4.8 2.8 2.3 3h.4c1.8 0 2.6-1.2 2.6-2.9v-5h.9l-.1 4.8zm10.6-4v7.8h-.9v-7.8H282v-.8h6.7v.8h-2.9zm11 7.7h-.9v-8.6h.9v8.6zm15.9-4.3c0 2.4-1.9 4.4-4.2 4.4-2.4 0-4.3-1.9-4.4-4.3v-.1c-.1-2.4 1.8-4.4 4.2-4.4 2.4-.1 4.4 1.8 4.4 4.2v.2zm8.1-2.9v7.1h-.8v-8.6h.8l5.5 7v-7h.8v8.6h-.6l-5.7-7.1zm19.7 4.9c-.1 1.4-1.3 2.5-2.7 2.5h-.2c-1.3 0-2.5-.5-3.4-1.4l-.1-.1.5-.6.1.1c.8.8 1.8 1.2 2.9 1.2.9 0 2-.5 2-1.5 0-1.2-1.3-1.4-2.2-1.6-1.4-.3-2.9-.8-2.9-2.4.1-1.4 1.3-2.5 2.7-2.4h.1c1.1-.1 2.1.3 2.9 1.1l.1.1-.4.5-.1-.1c-.7-.6-1.5-.9-2.4-.9-.9 0-1.9.5-1.9 1.4 0 1.3 1.3 1.4 2.3 1.7 1.3.2 2.7.8 2.7 2.4zm-212.8-5.4v3.8h2.2c1.2 0 2.4-.5 2.4-1.9s-1.2-1.9-2.3-1.9h-2.3zm17.1 4.6-2-4.3-2 4.3h4zm102.7-1.2c.1-1.9-1.4-3.5-3.2-3.6-1.9-.1-3.5 1.4-3.6 3.3v.4c-.1 1.9 1.4 3.5 3.3 3.6h.1c1.9 0 3.4-1.5 3.4-3.4v-.3zm64.3 0c.1-1.9-1.4-3.5-3.2-3.6-1.9-.1-3.5 1.4-3.6 3.3v.4c-.1 1.9 1.4 3.5 3.3 3.6h.1c1.9 0 3.4-1.5 3.4-3.4v-.3z"
      className="st2"
    />
    <path
      d="m349.6 23.2-1.2 1.7-1.2-1.7v2.6h-.3v-3.3h.3l1.2 1.9 1.3-1.9h.3v3.3h-.3v-2.6zM345.2 22.9v2.9h-.3v-2.9h-1.1v-.4h2.5v.4z"
      className="st2"
    />
  </svg>
);
