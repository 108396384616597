import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import {
  Balance,
  Landing,
  NotFound,
  Success,
  // Terms,
  // Kyc,
  // DynamicLinkFallback,
} from './components/pages';
import { ActivateCard } from './components/pages/ActivateCard/ActivateCard';
// import { EmailActivation } from './components/pages/EmailActivation';
import { Header } from './shared/header';
import { PageLayoutContainer } from './shared/Layout.styles';
import { Footer } from './shared/footer';
import ErrorBoundary from './shared/ErrorBoundary/ErrorBoundary';
// import { GetEftpos } from './components/pages/GetEftpos';

const Routes = () => {
  return (
    <HashRouter>
      <Header />
      <PageLayoutContainer>
        <ErrorBoundary>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/activate" component={ActivateCard} />
            <Route exact path="/success" component={Success} />
            {/* <Route exact path="/email-activation" component={EmailActivation} /> */}
            {/* <Route exact path="/get-eftpos" component={GetEftpos} /> */}
            <Route exact path="/balance" component={Balance} />
            {/* <Route exact path="/terms" component={Terms} /> */}
            {/* <Route exact path="/kyc" component={Kyc} />
            <Route exact path="/fallback" component={DynamicLinkFallback} /> */}
            <Route exact path="*" component={NotFound} />
          </Switch>
        </ErrorBoundary>
        <Footer />
      </PageLayoutContainer>
    </HashRouter>
  );
};

export default Routes;
