const black = '#000000';
export const white = '#ffffff';
export const grey = '#B4B4B9';
export const greyDark = '#282a37';
const greyMedium = '#d8d8d8';
export const greyLight = '#E0E4E7';
export const error = '#ff0033';
export const primary = process.env.REACT_APP_PRIMARY ?? '#000000';
const background = process.env.REACT_APP_BACKGROUND ?? '#ffffff';
export const subText = process.env.REACT_APP_SUBTEXT ?? '#B4B4B9';
export const subHeadingText =
  process.env.REACT_APP_SUBHEADING_TEXT ?? '#000000';
export const inputBorder =
  process.env.REACT_APP_INPUT_BORDER_COLOR ?? '#000000';
export const inputBorderOnFocusColor =
  process.env.REACT_APP_INPUT_BORDER_ON_FOCUS_COLOR ?? '#B4B4B9';
export const inputBorderOnHoverColor =
  process.env.REACT_APP_INPUT_BORDER_ON_HOVER_COLOR ?? '#000000';
export const inputTextColor =
  process.env.REACT_APP_INPUT_TEXT_COLOR ?? '#000000';

export const mainFontFamily = 'Montserrat';
export const fontFamily =
  '-apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif';

export const fontWeight = {
  light: '300',
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
};
export const theme = {
  colors: {
    primary: {
      default: primary,
    },
    secondary: {},
    black: {
      default: black,
    },
    blue: {},
    grey: {
      medium: greyMedium,
      default: grey,
      light: greyLight,
    },
    default: {},
    red: {},
    white: {
      default: white,
    },
    text: {
      default: primary,
      white: white,
      subHeadingText: subHeadingText,
      subText: subText,
      black: black,
    },
    error: error,
  },
  font: {
    family: fontFamily,
    size: {
      smallest: '12px',
      smaller: '13px',
      small: '14px',
      default: '15px',
      medium: '17px',
      large: '24px',
      heading: '30px',
      largeHeading: '35px',
      title: '50px',
      customHeading: '20px',
    },
    lineHeight: {
      default: '20px',
      small: '18px',
      button: '14px',
      medium: '20px',
      large: '24px',
      heading: '30px',
      largeHeading: '40px',
      title: '50px',
    },
    weight: {
      light: fontWeight.light,
      regular: fontWeight.regular,
      medium: fontWeight.medium,
      semiBold: fontWeight.semiBold,
      bold: fontWeight.bold,
    },
  },
  shadow: {},
  layout: {
    background: background,
    borderRadius: '40px',
    buttonWidth: '200px',
  },
  mQ: {
    mobileSml: 320,
    mobileMed: 480,
    mobileLrg: 560,
    tablet: 768,
    laptop: 1000,
    laptopLrg: 1160,
    desktopSml: 1350,
    desktop: 2560,
  },
  zIndex: {
    below: '-1',
    default: '0',
    above: '2',
    priority: '5',
    nav: '9',
    menu: '10',
    toggle: '11',
  },
};
